



































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Hook, HookFunctionType } from '@/models'
import { HookFragment, HookUpdateFragment } from './fragments'
import Loading from '@/components/Loading.vue'
import OptionFields from '@/components/form/OptionFields.vue'
import Fields from '@/components/form/Fields.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import gql from 'graphql-tag'
import cleanData from '@/utils/gql/cleanData'
import { confirmDelete } from '@/components/dialogs'

@Component({
  components: {
    Loading,
    OptionFields,
    Fields
  },
  apollo: {
    functionTypes: gql`
      query {
        functionTypes {
          _id
          name
          optionsParams
        }
      }
    `
  }
})
export default class HookEditLegacy extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String, required: true }) componentId!: String
  @Prop({ type: Object, required: true }) savedHook!: Hook

  saving = false
  valid = false

  functionTypes: HookFunctionType[] | null = null
  hook: Partial<Hook> = {}

  mounted() {
    this.update(this.savedHook)
  }

  @Watch('savedHook')
  update(savedHook: Hook) {
    this.$set(this, 'hook', _cloneDeep(savedHook))
  }

  @Watch('hook.name')
  updateName(newName: string) {
    this.$emit('name', newName)
  }

  get dirty() {
    return !_isEqual(this.hook, this.savedHook)
  }

  get functionType() {
    if (!this.functionTypes) return null
    return this.functionTypes.find((f) => this.hook.functionTypeId === f._id)
  }

  get schema() {
    if (!this.functionType) return {}
    return this.functionType.optionsParams
  }

  async save() {
    if (!this.hook || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($hookId: ID, $hook: UpdateHookInput) {
            updateHook(hookId: $hookId, hook: $hook) {
              ...Hook
            }
          }
          ${HookFragment}
        `,
        // Parameters
        variables: {
          hookId: this.hook._id,
          hook: cleanData(this.hook, HookUpdateFragment)
        }
      })

      this.savedHook = result.data.updateHook
      this.$emit('save', result.data.updateHook)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        '¿Seguro que quieres eliminar por completo este hook del ambiente?'
      ))
    )
      return
    if (!this.hook || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($hookId: ID) {
            removeHook(hookId: $hookId)
          }
        `,
        // Parameters
        variables: {
          hookId: this.hook._id
        }
      })
      this.$emit('delete', result.data.removeHook)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
